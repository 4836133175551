import React from "react"
import { Box, Text } from "theme-ui"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import SubInfoBox from "../../components/SubInfoBox"
import ContentSection from "../../components/ContentSection"
import SEO from "../../components/SEO"
import ReferenceLink from "../../components/UpscReference/ReferenceLink"

const links = [
  {
    text: "2019 UPSC Cut Off Marks",
    href: "/pdf/2019-UPSC-Cut-Off-Marks.pdf",
  },
  {
    text: "UPSC Calendar 2020",
    href: "/pdf/UPSC-Calendar-2020.pdf",
  },
  {
    text: "UPSC Calendar 2021",
    href: "/pdf/UPSC-Calendar-2021.pdf",
  },
]

const ImportantLinksPage = (): JSX.Element => (
  <Box>
    <SEO title="Crucial links that you shouldn't miss include.." />
    <Layout>
      <PageHeader>
        <Text
          sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
        >
          Crucial links that you shouldn't miss include..
        </Text>
      </PageHeader>
      <SubInfoBox>
        Get the latest UPSC Reference Material prepared by Sarkar IAS Academy
      </SubInfoBox>
      <ContentSection
        header="Crucial links that you shouldn't miss include.."
        noHeaderBorder={true}
        body={
          <Box mt={[-4, 0]}>
            {links.map(link => (
              <ReferenceLink name={link.text} href={link.href} />
            ))}
          </Box>
        }
      />
    </Layout>
  </Box>
)

export default ImportantLinksPage
